import React from 'react';
import PageAbout from '../components/about/page';
import SEOHelmet from '../components/helmet';
import Layout from '../components/layout';
import PageHeader from '../components/pageHeader';
import Services from '../components/services';
import Testimonial from '../components/testimonials/home-two';

import ServiceThumb from '../assets/img/about.png';
import bgImg from '../assets/img/page-header.jpg';

function PageService() {
  return (
    <Layout>
      <SEOHelmet
        title="Our Services - Tailored IT Solutions for Businesses Across Diverse Industries"
        description="Explore Vendoz Technologies' diverse range of top-tier IT services tailored to meet the unique needs of businesses across diverse industries, including digital commerce solutions, full-stack services, staff augmentation, client-centric approach, cloud services, and customer experience services."
        keywords="IT services, Vendoz Technologies, digital commerce solutions, full-stack services, staff augmentation, client-centric approach, cloud services, customer experience services"
        canonical="https://vendoz.in/services"
      />
      <PageHeader
        bgImg={bgImg}
        title="OUR SERVICES"
        content="Vendoz always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
      />
      <PageAbout
        title="Our Services"
        heading="Provide best <br/> Business Solutions"
        thumb={ServiceThumb}
        content="<b>Vendoz</b> offers top-tier,
        diverse IT services tailored to client
        needs, specializing in cutting-edge
        solutions for the dynamic E-commerce
        sector across various industries. Our
        dedicated team excels in providing
        specialized services, ensuring
        innovation and success."
      />
      <Services classes="sm-top" />
      <Testimonial />
    </Layout>
  );
}

export default PageService;
