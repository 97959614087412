import React from 'react';

function OffCanvas({ type, children }) {
  const mobileMenuClose = () => {
    const canvasWrapper = document.querySelector('.off-canvas-menu');
    canvasWrapper.classList.remove('active');
    document.querySelector('body').classList.remove('fix');
  };

  return (
    <aside className={`off-canvas-wrapper off-canvas-${type}`}>
      <div onClick={mobileMenuClose} className="off-canvas-overlay" />
      <div className="off-canvas-inner">
        <div className="off-canvas-content">{children}</div>
      </div>
    </aside>
  );
}

export default OffCanvas;
