/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
import React from 'react';
import SEOHelmet from '../../components/helmet';
import PageHeader from '../../components/pageHeader';
import PageWrapper from '../../components/pageWrapper';
import RelatedServices from '../../components/relatedServices';
import Sidebar from '../../components/sidebar';
import SidebarItem from '../../components/sidebar/SidebarItem';
import List from '../../components/ui/list';
import LI from '../../components/ui/list/Item';
import ServiceContentWrap from './ServiceContentWrap';

import ServicesData from '../../data/services/services.json';

function ServiceDetails() {
  const serviceIndex = ServicesData.findIndex((service) =>
    window.location.pathname.includes(service.path)
  );
  let prevService;
  let nextService;
  serviceIndex === 0
    ? (prevService = ServicesData[serviceIndex])
    : (prevService = ServicesData[serviceIndex - 1]);
  serviceIndex + 1 === ServicesData.length
    ? (nextService = ServicesData[serviceIndex])
    : (nextService = ServicesData[serviceIndex + 1]);

  return (
    <>
      <SEOHelmet
        title={ServicesData[serviceIndex].seo.title}
        description={ServicesData[serviceIndex].seo.description}
        keywords={ServicesData[serviceIndex].seo.keywords}
        canonical={`https://vendoz.in/${ServicesData[serviceIndex].path}`}
      />

      <PageHeader
        bgImg={require('../../assets/img/page-header.jpg')}
        title={ServicesData[serviceIndex].title}
        content={ServicesData[serviceIndex].shortDesc}
      />

      <PageWrapper classes="sm-top service-details-wrapper">
        <ServiceContentWrap
          service={ServicesData[serviceIndex]}
          totalService={ServicesData.length}
          nextService={nextService}
          prevService={prevService}
        />

        <Sidebar classes="col-lg-4">
          <SidebarItem title="Services">
            <List classes="service-list">
              {ServicesData.map((serviceItem) => (
                <LI key={serviceItem.id}>
                  <a
                    href={`${`${process.env.PUBLIC_URL}/service/${serviceItem.title
                      .split(' ')
                      .join('-')
                      .toLowerCase()}`}`}
                  >
                    {serviceItem.title}
                  </a>
                </LI>
              ))}
            </List>
          </SidebarItem>

          {/* <SidebarItem title="Download Brochure">
            <List classes="service-list">
              <LI>
                <Anchor path="/">
                  <i className="fa fa-file-pdf-o" />
                  Brochures.pdf
                </Anchor>
              </LI>
            </List>
          </SidebarItem> */}
        </Sidebar>
      </PageWrapper>

      <RelatedServices />
    </>
  );
}

export default ServiceDetails;
