/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import React from 'react';
import { Link } from 'react-router-dom';
import { LazyImage } from '../lazyImage';

function ServiceItem({ title, thumb, text }) {
  const serviceURL = `/service/${title.split(' ').join('-').toLowerCase()}`;
  return (
    <div className="col-sm-6 col-lg-4">
      <div className="service-item">
        <figure className="service-thumb">
          <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
            <LazyImage src={require(`../../assets/img/${thumb}`)} alt={title} />
          </Link>

          <figcaption className="service-txt">
            <h5>{title}</h5>
          </figcaption>
        </figure>
        <div className="service-content">
          <div className="service-content-inner">
            <h5>{title}</h5>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
