import React from 'react';

import About from '../components/about/home-one';
import Features from '../components/features';
import SEOHelmet from '../components/helmet';
import Layout from '../components/layout';
import Services from '../components/services';
import Slider from '../components/slider/home-one';
import Testimonials from '../components/testimonials/home-one';

function Home() {
  return (
    <Layout>
      <SEOHelmet
        title="Vendoz Technologies - Empowering Businesses through Innovative IT Solutions"
        description="Welcome to Vendoz Technologies, your trusted partner for comprehensive digital commerce and transformation solutions. Explore our services and discover how we can help your business succeed in the dynamic digital landscape."
        keywords="digital commerce, digital transformation, IT solutions, ecommerce, software development, web development, app development"
        canonical="https://vendoz.in/"
      />
      <Slider />
      <About />
      <Features classes="sp-top" />
      <Services classes="sm-top-wt" />
      <Testimonials />
    </Layout>
  );
}

export default Home;
