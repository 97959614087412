import React from 'react';
import ContactUsForm from './ContactUsForm';
import ContactInfo from './ContactInfo';

function ContactPage() {
  return (
    <div className="contact-page-area-wrapper sp-y">
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form-area contact-method">
                <h3>Send us a Message</h3>
                <ContactUsForm />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-information contact-method">
                <div className="contact-info-con">
                  <h3>Contact Info</h3>
                  <ContactInfo
                    color="white"
                    address="Vendoz Technologies Pvt Ltd,<br />Old# 43/1, New# 19/1,<br />2nd Floor, Taylors Road, Kilpauk,<br />Chennai - 600010, Tamil Nadu"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
