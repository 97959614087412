import React from 'react';
import SectionTitle from '../ui/sectionTitle';
import ServiceItem from './ServiceItem';
import { LazyBackgroundImage } from '../lazyImage';

import bgImg from '../../assets/img/service/service-bg.webp';
import ServicesData from '../../data/services/services.json';

function Services({ classes }) {
  return (
    <div className={`service-area-wrapper ${classes}`}>
      <LazyBackgroundImage className="service-area-top" src={bgImg}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-5 m-auto text-center">
              <SectionTitle
                variant="light"
                title="Our Services"
                heading="We make it simple, providing best solutions"
              />
            </div>
          </div>
        </div>
      </LazyBackgroundImage>

      <div className="service-content-area">
        <div className="container">
          <div className="row mtn-30">
            {ServicesData.map((service) => (
              <ServiceItem
                key={service.id}
                title={service.title}
                text={service.shortDesc}
                thumb={service.thumb}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
