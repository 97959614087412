import React from 'react';
import SEOHelmet from '../components/helmet';
import Layout from '../components/layout';
import PageHeader from '../components/pageHeader';
import ContactPage from '../templates/contact';

import bgImg from '../assets/img/page-header.jpg';

function PageContact() {
  return (
    <Layout>
      <SEOHelmet
        title="Contact Vendoz Technologies - Get in Touch for Customized IT Solutions"
        description="Contact Vendoz Technologies to discuss your IT requirements and explore how our tailored solutions can drive growth and innovation for your business. Reach out to our team today."
        keywords="contact Vendoz Technologies, IT solutions, business growth, innovation, digital commerce, digital transformation"
        canonical="https://vendoz.in/contact"
      />
      <PageHeader
        bgImg={bgImg}
        title="CONTACT US"
        content="Vendoz always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
      />
      <ContactPage />
    </Layout>
  );
}

export default PageContact;
