import React from 'react';

export function NextArrow({ className, onClick }) {
  return (
    <button className={className} onClick={onClick} type="button" aria-label="angle-right-icon">
      <i className="fa fa-angle-right" />
    </button>
  );
}

export function PrevArrow({ className, onClick }) {
  return (
    <button className={className} onClick={onClick} type="button" aria-label="angle-right-icon">
      <i className="fa fa-angle-left" />
    </button>
  );
}
