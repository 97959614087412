/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import React from 'react';

export default function LazyImage({ src, srcset, alt }) {
  return <img data-src={src} data-srcset={srcset} className="lazyload" alt={alt} />;
}
