/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import 'lazysizes';
import React from 'react';

export default function LazyBackgroundImage({ src, children, className }) {
  return (
    <div
      className={`${className} lazyload`}
      data-bg={src}
      style={{ backgroundImage: `url(${src})` }}
    >
      {children}
    </div>
  );
}
