import React from 'react';
import CallToAction from '../callToAction';
import Footer from '../footer';
import Header from '../header';
import MobileMenu from '../mobileMenu';

function Layout({ children }) {
  return (
    <>
      <Header />
      {children}
      <CallToAction />
      <Footer />
      <MobileMenu />
    </>
  );
}

export default Layout;
