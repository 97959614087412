import React from 'react';

function HeaderConfig(props) {
  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector('.off-canvas-menu');
    offCanvasMenu.classList.add('active');
  };

  return (
    <div className="header-action text-right">
      {/* <a href="tel:00199823568658" className="tel-no">
        +998 23568 658
      </a>
      <button onClick={LoginRegHandler} className="btn-cog">
        <i className="fa fa-cog" />
      </button> */}
      <button
        onClick={MobileMenuHandler}
        className="btn-menu d-lg-none"
        type="button"
        aria-label="bars"
      >
        <i className="fa fa-bars" />
      </button>
    </div>
  );
}

export default HeaderConfig;
