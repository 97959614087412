/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { LazyImage } from '../lazyImage';

function FeatureItem({ title, img, text }) {
  return (
    <div className="col-md-4">
      <div className="icon-box-item">
        <div className="icon-box__icon">
          <LazyImage src={require(`../../assets/img/${img}`)} alt="Vendoz-Feature" />
        </div>
        <div className="icon-box__info">
          <h5>{title}</h5>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default FeatureItem;
