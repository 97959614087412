import React from 'react';
import About from '../components/about/home-two';
import Features from '../components/features';
import SEOHelmet from '../components/helmet';
import Layout from '../components/layout';
import PageHeader from '../components/pageHeader';
import Team from '../components/team/home-one';
import Testimonials from '../components/testimonials/home-one';

import pageHeaderImg from '../assets/img/page-header.jpg';

function PageAbout() {
  return (
    <Layout>
      <SEOHelmet
        title="About Vendoz Technologies - Empowering Businesses with Tailored IT Solutions"
        description="Learn more about Vendoz Technologies, a team of skilled IT professionals dedicated to delivering top-tier IT services and empowering businesses across diverse industries with innovative software solutions."
        keywords="Vendoz Technologies, IT professionals, software development, digital commerce, digital transformation, ecommerce solutions"
        canonical="https://vendoz.in/about"
      />
      <PageHeader
        bgImg={pageHeaderImg}
        title="ABOUT US"
        content="Vendoz is a leading provider of customized software solutions, specializing in E-commerce, with a dedicated focus on delivering top-tier IT services for client success in the digital landscape."
      />
      <About />
      {/* <Services /> */}
      <Features classes="sm-top" />
      {/* <BrandLogo /> */}
      <Team />
      <Testimonials />
    </Layout>
  );
}

export default PageAbout;
