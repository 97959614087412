import React from 'react';
import { Link } from 'react-router-dom';
import LI from '../../ui/list/Item';

import navbarData from '../../../data/navbar/navbar.json';

function NavbarItem() {
  return navbarData.map((item) => (
    <li key={item.id} className={item.subMenu || item.megaMenu ? 'has-submenu' : ''}>
      <Link to={`${item.link}`}>{item.title}</Link>
      {(() => {
        if (item.subMenu) {
          return (
            <ul className="submenu-nav">
              {item.subMenu.map((subItem) => (
                <LI
                  key={subItem.id}
                  className={window.location.pathname.includes(subItem.link) && 'active'}
                >
                  <a href={subItem.link}>{subItem.title}</a>
                </LI>
              ))}
            </ul>
          );
        }

        if (item.megaMenu) {
          return (
            <ul className="submenu-nav submenu-nav-mega">
              {item.megaMenu.map((megaItem, indx) => (
                <li key="submenu-nav-mega" className="mega-menu-item">
                  <Link to={megaItem.link}>{megaItem.title}</Link>
                  <ul>
                    {megaItem.lists.map((listItem, idx) => (
                      <li key="submenu-nav-mega-item">
                        <Link to={`${process.env.PUBLIC_URL + listItem.link}`}>
                          {listItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          );
        }
        return null;
      })()}
    </li>
  ));
}

export default NavbarItem;
