import React from 'react';
import logo from '../../assets/img/logo.png';
import { LazyImage } from '../lazyImage';

function Logo() {
  return (
    <div className="logo-area">
      <a href="/">
        <LazyImage src={logo} alt="Vendoz-Logo" />
      </a>
    </div>
  );
}

export default Logo;
