import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/ui/input';

function ContactUsForm({ history }) {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [formSubmissionResponse, setFormSubmissionResponse] = useState({ success: '', error: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const resetForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    });
    setErrors({
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    });
  };

  useEffect(() => {
    if (!isSubmitted) {
      return () => {};
    }

    const timeout = setTimeout(() => {
      if (formSubmissionResponse.success) {
        navigate('/');
      }
      setIsSubmitted(false);
      setFormSubmissionResponse({ success: '', error: '' });
    }, 5000);

    return () => clearTimeout(timeout);
  }, [formSubmissionResponse.success, formSubmissionResponse.error, isSubmitted, navigate]);

  const sendEmail = (formData) => {
    fetch(process.env.REACT_APP_FORMSPREE_URL, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          setFormSubmissionResponse({
            success: "Thank you for your submission! We'll be in touch shortly.",
            error: ''
          });
          resetForm();
          return;
        }

        throw new Error('Oops! There was a problem submitting your form');
      })
      .catch((error) => {
        setFormSubmissionResponse({
          success: '',
          error: error.message
        });
      });
  };

  const handleSubmit = async (e) => {
    setIsSubmitted(true);
    e.preventDefault();

    // Validation
    const newErrors = {};
    Object.keys(form).forEach((key) => {
      if (form[key] && key === 'email' && !isValidEmail(form[key])) {
        newErrors[key] = 'Invalid email format';
      } else if (form[key].trim() === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      } else {
        newErrors[key] = '';
      }
    });
    setErrors(newErrors);

    // Check if there are no errors before submitting
    const hasErrors = Object.values(newErrors).some((error) => error !== '');
    if (!hasErrors) {
      const data = new FormData(e.target);
      if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
        sendEmail(data);
      }
    }
  };

  const handleInputChange = (e, formName) => {
    setForm({ ...form, [formName]: e.target.value });
    setErrors({ ...errors, [formName]: '' });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="contact-form-wrap">
      <form id="contact-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <FormInput
              tag="input"
              type="text"
              name="firstName"
              placeholder="First Name *"
              onChange={(e) => handleInputChange(e, 'firstName')}
              value={form.firstName}
              error={errors.firstName}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              tag="input"
              type="text"
              name="lastName"
              placeholder="Last Name *"
              onChange={(e) => handleInputChange(e, 'lastName')}
              value={form.lastName}
              error={errors.lastName}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              tag="input"
              type="text"
              name="email"
              placeholder="Email address *"
              onChange={(e) => handleInputChange(e, 'email')}
              value={form.email}
              error={errors.email}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              tag="input"
              type="text"
              name="phone"
              placeholder="Phone No (With Country Code)"
              onChange={(e) => handleInputChange(e, 'phone')}
              value={form.phone}
            />
          </div>

          <div className="col-12">
            <FormInput
              tag="textarea"
              name="message"
              placeholder="Write Your Message *"
              onChange={(e) => handleInputChange(e, 'message')}
              value={form.message}
              error={errors.message}
            />

            <FormInput tag="button" type="submit" classes="btn-outline" />

            <div className="form-message" />
          </div>
        </div>
      </form>
      {formSubmissionResponse.success ? (
        <div className="alert alert-success alert-dismissible" role="alert">
          <div>{formSubmissionResponse.success}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setIsSubmitted(false);
              setFormSubmissionResponse({ error: '', success: '' });
              navigate('/');
            }}
          />
        </div>
      ) : null}
      {formSubmissionResponse.error ? (
        <div className="alert alert-danger alert-dismissible" role="alert">
          <div>{formSubmissionResponse.error}</div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setIsSubmitted(false);
              setFormSubmissionResponse({ error: '', success: '' });
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ContactUsForm;
