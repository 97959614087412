import React from 'react';
import { LazyBackgroundImage } from '../../lazyImage';
import SectionTitle from '../../ui/sectionTitle';
import SlickSlider from '../../ui/slick';
import Member from './Member';

import teamBg from '../../../assets/img/team/team-bg.jpg';
import Teams from '../../../data/team/home-one.json';

function Team() {
  const settings = {
    slidesToShow: 2,
    arrows: false,
    autoplay: false,
    dots: true,
    className: 'team-content-wrap slick-dots--light mtn-md-5',
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <LazyBackgroundImage className="team-area-wrapper bg-img sp-y sm-top" src={teamBg}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <SectionTitle
              variant="light"
              title="Creative Team"
              heading="We have great <br />creative team"
              text="<strong>Vendoz</strong> always try to provide the best Business Solutions for Clients to grow up their Business sharp and smoothly."
            />
          </div>

          <div className="col-lg-8">
            <SlickSlider settings={settings}>
              {Teams.map((team) => (
                <div key={team.id}>
                  <Member
                    id={team.id}
                    name={team.name}
                    designation={team.designation}
                    profilePic={team.profilePic}
                  />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </LazyBackgroundImage>
  );
}

export default Team;
