import React from 'react';
import { LazyBackgroundImage } from '../lazyImage';

function PageHeader({ bgImg, title, content }) {
  return (
    <LazyBackgroundImage className="page-header-area bg-img" src={bgImg}>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-xl-8 m-auto text-center">
            <div className="page-header-content-inner">
              <div className="page-header-content">
                <h2>{title}</h2>
                <p>{content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LazyBackgroundImage>
  );
}

export default PageHeader;
