/* eslint-disable react/no-danger */
import React from 'react';
import { Link } from 'react-router-dom';
import socialNetworks from '../../data/socialNetworks/socials.json';

function ContactInfo({ address, color, hideSocialLinks, changeColorOnHover }) {
  return (
    <>
      <div className="widget-item m-0">
        <address className={changeColorOnHover && 'highlight'}>
          <Link
            to="https://maps.app.goo.gl/BTaDjJKHVsb9owUb6"
            dangerouslySetInnerHTML={{ __html: address }}
            target="_blank"
            className={`text-${color}`}
          />
        </address>
        <div className="mt-2">
          <span className={changeColorOnHover && 'highlight'}>
            <i className={`fa fa-envelope text-${color} me-2`} />
            <Link to="mailto:admin@vendoz.in" className={`text-${color}`}>
              admin@vendoz.in
            </Link>
          </span>
        </div>
        <div>
          <span className={changeColorOnHover && 'highlight'}>
            <i className={`fa fa-phone text-${color} me-2`} />
            <Link to="tel:+91 97510 26364" className={`text-${color}`}>
              +91 97510 26364
            </Link>
          </span>
        </div>
      </div>
      {!hideSocialLinks ? (
        <div className="member-social-icons mt-30">
          {socialNetworks.map((social) => (
            <a
              key={social.id}
              href={`${social.url}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="social-network-name"
            >
              <i className={`fa fa-${social.networkName} text-${color}`} />
            </a>
          ))}
        </div>
      ) : null}
    </>
  );
}

export default ContactInfo;
