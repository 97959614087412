/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

function FormInput({ tag, classes, error, ...props }) {
  // Determine additional classes based on error
  const inputClasses = error ? `${classes} error` : classes;

  return (
    <div className="single-input-item">
      <label htmlFor="input-field">
        {tag === 'input' && <input className={inputClasses} id="input-field" {...props} />}
        {tag === 'textarea' && (
          <textarea cols="30" rows="7" className={inputClasses} id="input-field" {...props} />
        )}
        {tag === 'button' && (
          // eslint-disable-next-line react/button-has-type
          <button className={`btn-outline ${inputClasses}`} {...props}>
            Send Message
          </button>
        )}
      </label>
      {/* Display error message if exists */}
      {error && <span className="error-message">{error}</span>}
    </div>
  );
}

export default FormInput;
