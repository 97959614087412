import React from 'react';
import { Link } from 'react-router-dom';
import { NextArrow, PrevArrow } from '../../../helpers/utils';
import SlickSlider from '../../ui/slick';
import { LazyBackgroundImage } from '../../lazyImage';

import SliderData from '../../../data/slider/home-1.json';

function Slider() {
  const settings = {
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  };

  return (
    <div className="slider-area">
      <SlickSlider settings={settings}>
        {SliderData.map((item) => (
          <div key={item.id}>
            <LazyBackgroundImage
              className="slider-item"
              src={`${require(`../../../assets/img/${item.bg}`)}`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-7">
                    <div className="slider-content">
                      <h2>{item.title}</h2>
                      <p>{item.text}</p>
                      <Link
                        to={`${process.env.PUBLIC_URL + item.btnLink}`}
                        className="btn btn-brand"
                      >
                        {item.btnText}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </LazyBackgroundImage>
          </div>
        ))}
      </SlickSlider>
    </div>
  );
}
export default Slider;
