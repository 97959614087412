import React from 'react';
import { Helmet } from 'react-helmet';

function SEOHelmet({ title, description, keywords, canonical }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="author" content="Vendoz Technologies" />
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="robots" content="index,follow" />
    </Helmet>
  );
}

export default SEOHelmet;
