/* eslint-disable react/destructuring-assignment */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import React, { Component } from 'react';
import Slider from 'react-slick';
import quote from '../../../assets/img/icons/quote.png';
import { LazyImage } from '../../lazyImage';
import SectionTitle from '../../ui/sectionTitle';

import testimonialData from '../../../data/testimonials/testimonials.json';

function NextArrow({ className, onClick }) {
  return (
    <button className={className} onClick={onClick} type="button" aria-label="arrow-right-icon">
      <i className="fa fa-long-arrow-right" />
    </button>
  );
}

function PrevArrow({ className, onClick }) {
  return (
    <button className={className} onClick={onClick} type="button" aria-label="arrow-left-icon">
      <i className="fa fa-long-arrow-left" />
    </button>
  );
}

class Testimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const testSettings = {
      slidesToShow: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      className: 'testimonial-content--2',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
    };

    return (
      <div className="testimonial-area testimonial-area--2 bg-offwhite">
        <div className="container">
          <div className="row d-lg-none">
            <div className="col-12 text-center">
              <SectionTitle title="TESTIMONIALS" heading="Our Customer Loves What we do" />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-5">
              <Slider
                asNavFor={this.state.nav2}
                ref={(slider) => {
                  this.slider1 = slider;
                }}
                arrows={false}
                className="testimonial-thumbnail mt-sm-5 mt-md-1"
              >
                {testimonialData.map((testimonial) => (
                  <div key={testimonial.id}>
                    <div className="testimonial-thumbnail-item d-flex justify-content-center">
                      <LazyImage
                        src={require(`../../../assets/img/${testimonial.authorThumb}`)}
                        alt="Vendoz-Testimonial"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>

            <div className="col-md-7 ml-auto">
              <div className="testimonial-area-right">
                <div className="d-none d-lg-block">
                  <SectionTitle title="TESTIMONIALS" heading="Our Customer <br/>Loves What we do" />
                </div>

                <div className="testimonial-content-wrap pl-0">
                  <Slider
                    asNavFor={this.state.nav1}
                    ref={(slider) => {
                      this.slider2 = slider;
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...testSettings}
                  >
                    {testimonialData.map((testiItem) => (
                      <div key={testiItem.id}>
                        <div className="testimonial-item testimonial-item--2">
                          <div className="testimonial-txt">
                            <LazyImage src={quote} alt="Vendoz" />
                            <p>{testiItem.quote}</p>
                            <h5 className="client-name">
                              {testiItem.author},{' '}
                              <span className="designation">{testiItem.designation}</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
