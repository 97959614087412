import React from 'react';

function LI({ children, html, className }) {
  if (html) {
    return <li dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return <li className={className}>{children}</li>;
}

export default LI;
