import React from 'react';
import SectionTitle from '../ui/sectionTitle';
import ServiceItem from '../services/ServiceItem';

import ServicesData from '../../data/services/services.json';

function RelatedServices() {
  return (
    <div className="related-service-area sm-top-wt">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto text-center">
            <SectionTitle title="More Services" heading="Related Services" />
          </div>
        </div>

        <div className="row mtn-30">
          {ServicesData.reverse()
            .slice(0, 3)
            .map((relatedService) => (
              <ServiceItem
                key={relatedService.id}
                title={relatedService.title}
                text={relatedService.shortDesc}
                thumb={relatedService.thumb}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default RelatedServices;
