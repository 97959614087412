import React from 'react';
import OffCanvas from '../ui/offCanvas';
import NavbarItem from '../header/navbar/NavbarItem';

function MobileMenu(type) {
  const LoginRegClose = () => {
    const canvasWrapper = document.querySelector('.off-canvas-cog');
    canvasWrapper.classList.remove('active');
    document.querySelector('body').classList.remove('fix');
  };

  const MobileMenuClose = () => {
    const canvasWrapper = document.querySelector('.off-canvas-menu');
    canvasWrapper.classList.remove('active');
    document.querySelector('body').classList.remove('fix');
  };

  return (
    <OffCanvas type="menu">
      <div className="res-mobile-menu h-100">
        <div className="close-btn">
          <button
            onClick={type === 'cog' ? LoginRegClose : MobileMenuClose}
            className="btn-close btn-close-white"
            type="button"
            aria-label="nav-bar"
          />
        </div>
        <div className="mobile-nav text-center">
          <NavbarItem />
        </div>
      </div>
    </OffCanvas>
  );
}

export default MobileMenu;
