/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import ContactInfo from '../../templates/contact/ContactInfo';
import { LazyImage } from '../lazyImage';
import List from '../ui/list';
import LI from '../ui/list/Item';
import Text from '../ui/text';
import Widget from '../ui/widget';

function Footer() {
  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40 justify-content-between">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${`${process.env.PUBLIC_URL}/`}`}>
                  <LazyImage src={Logo} alt="Logo" />
                </Link>

                <Text>Empowering Your Digital Success</Text>

                <Text classes="copyright-txt">
                  &copy; {new Date().getFullYear()} Vendoz Technologies Pvt Ltd. All Rights
                  Reserved.
                </Text>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Information">
              <List classes="widget-list">
                <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/about`}`}>Our Company</Link>
                </LI>
                {/* <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/`}`}>What We Do</Link>
                </LI>
                <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/`}`}>Why Choose Us</Link>
                </LI>
                <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/`}`}>Our Mission</Link>
                </LI>
                <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/`}`}>Our Core Values</Link>
                </LI> */}
                <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/services`}`}>Our Services</Link>
                </LI>
                <LI>
                  <Link to={`${`${process.env.PUBLIC_URL}/contact`}`}>Contact Us</Link>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Social Links">
              <List classes="widget-list">
                <LI>
                  <Link to="https://www.linkedin.com/in/vendoz-technologies/" target="_blank">
                    LinkedIn
                  </Link>
                </LI>
                <LI>
                  <Link to="https://twitter.com/vendoztech" target="_blank">
                    Twitter
                  </Link>
                </LI>
                <LI>
                  <Link to="https://www.youtube.com/@Vendoz_Tech" target="_blank">
                    Youtube
                  </Link>
                </LI>
                <LI>
                  <Link to="https://www.instagram.com/vendoztech/" target="_blank">
                    Instagram
                  </Link>
                </LI>
              </List>
            </Widget>
          </div>

          <div className="col-md-4 col-lg-3">
            <Widget title="Reach Us">
              <ContactInfo
                color="black"
                address="Vendoz Technologies Pvt Ltd,<br />Old# 43/1, New# 19/1,<br />2nd Floor, Taylors Road, Kilpauk,<br />Chennai - 600010, Tamil Nadu"
                changeColorOnHover
                hideSocialLinks
              />
            </Widget>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
