import React from 'react';
import parse from 'html-react-parser';

function SectionTitle({ variant, title, heading, text, tagline }) {
  return (
    <div className={`section-title ${variant === 'light' ? 'section-title--light' : ''}`}>
      <h6>{title}</h6>
      <h2>{parse(heading)}</h2>
      {text && <p>{parse(text)}</p>}
      {tagline && <h5 className="tagline">{parse(tagline)}</h5>}
    </div>
  );
}

export default SectionTitle;
