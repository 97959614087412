import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ScrollToTop from './helpers/scrollToTop';
import About from './pages/About';
import Contact from './pages/Contact';
import Error404 from './pages/Error404';
import Home from './pages/Home';
import Service from './pages/Service';
import ServiceDetails from './pages/ServiceDetails';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path={`${`${process.env.PUBLIC_URL}/home`}`} element={<Home />} />
          <Route exact path={`${'/services'}`} element={<Service />} />
          <Route
            exact
            path={`${`${process.env.PUBLIC_URL}/service/:serviceName`}`}
            element={<ServiceDetails />}
          />
          <Route exact path={`${`${process.env.PUBLIC_URL}/about`}`} element={<About />} />
          <Route exact path={`${`${process.env.PUBLIC_URL}/contact`}`} element={<Contact />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
