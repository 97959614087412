import React, { Component } from 'react';
import Logo from './Logo';
import Navbar from './navbar/Navbar';
import HeaderConfig from './HeaderConfig';

class Header extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.isSticky);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.isSticky);
  }

  isSticky = (e) => {
    const header = document.querySelector('header');
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add('sticky') : header.classList.remove('sticky');
  };

  render() {
    const { mobileMenuShow } = this.props;
    return (
      <header className="header-area">
        <div className="container">
          <div className="row align-items-end justify-content-between">
            <div className="col-5 col-lg-2">
              <Logo />
            </div>

            <div className="col-6 col-lg-6 d-none d-lg-block">
              <div className="navigation-area">
                <Navbar />
              </div>
            </div>

            <div className="col-1 col-lg-1 d-lg-none p-0 d-flex justify-content-center">
              <HeaderConfig mobileMenuShow={mobileMenuShow} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
