/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import React from 'react';
import { LazyImage } from '../../lazyImage';

function Member({ id, name, designation, type, profilePic }) {
  return type === 'page' ? (
    <div className="col-sm-6 col-lg-3">
      <div className="team-mem-item">
        <figure className="member-pic">
          <LazyImage src={require(`../../../assets/img/${profilePic}`)} alt={name} />
        </figure>
        <div className="member-info">
          <h5>{name}</h5>
          <span className="designation">{designation}</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="team-mem-item">
      <figure className="member-pic">
        <LazyImage src={require(`../../../assets/img/${profilePic}`)} alt={name} />
      </figure>
      <div className="member-info">
        <h5>{name}</h5>
        <span className="designation">{designation}</span>
      </div>
    </div>
  );
}

export default Member;
