import React from 'react';
import { LazyImage } from '../../lazyImage';

function Thumbnail({ imgSrc, classes }) {
  return (
    <figure className={classes}>
      <LazyImage src={imgSrc} alt="Vendoz" />
    </figure>
  );
}

export default Thumbnail;
