/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import React from 'react';
import quoteIcon from '../../../assets/img/icons/quote.png';
import { LazyImage } from '../../lazyImage';

function TestimonialItem({ authorThumb, quote, author, designation }) {
  return (
    <div className="testimonial-item testimonial-item--3">
      <div className="testimonial-thumb">
        <LazyImage src={require(`../../../assets/img/${authorThumb}`)} alt="Vendoz" />
      </div>

      <div className="testimonial-txt">
        <LazyImage src={quoteIcon} alt="Vendoz" />
        <p>{quote}</p>
        <h5 className="client-name">
          {author}, <span className="designation">{designation}</span>
        </h5>
      </div>
    </div>
  );
}

export default TestimonialItem;
