/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useNavigate } from 'react-router';

const withRouter = (Component) => {
  function Wrapper(props) {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  }
  return Wrapper;
};

export default withRouter;
